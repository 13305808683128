<!--
 * @Autor: oops.liu
 *常见问题页面
-->
<template>
  <div class="problem">
    <div class="content_box">
      <scroller>
        <div class="content">
          <van-collapse v-model="activeNames" accordion>
            <van-collapse-item
              v-for="(item, index) in list"
              :key="index"
              :title="`Q${index + 1}:${item.name}`"
              :name="index"
              >{{ item.content }}</van-collapse-item
            >
          </van-collapse>
        </div>
      </scroller>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import handleLeft from "@/mixin/handleLeft.js";
export default {
  components: {},
    mixins: [handleLeft],
  props: {},
  data() {
    return {
      activeNames: ["1"],
      list: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions({ load: "refund/getQAList" }),
    async loadInfo() {
      const info = await this.load({ type: this.type });
      this.list = info;
    },
  
  },
  created() {
    this.loadInfo();
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.problem {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  .nav_bar_box {
    ::v-deep .van-nav-bar {
      .van-nav-bar__left {
        .van-icon {
          color: #666;
        }
      }
    }
  }
  .content_box {
    position: relative;
    width: 100%;
    // height: calc(100vh - 46px);
    height: 100%;
    box-sizing: border-box;
    .content {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      ::v-deep .van-collapse {
        .van-collapse-item {
          margin-bottom: 10px;
          border-radius: 7px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
